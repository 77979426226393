<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户行为分析</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <!--标题-->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="is_auth('usermsgtpl.wxtemplate.issave')" icon="el-icon-plus" size="medium" type="primary"
              @click="addTemplate">添加
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column label="模板类型" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.text.title }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="模板名称" show-overflow-tooltip />
        <el-table-column label="模板内容" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-popover v-if="scope.row.text.data" placement="right" trigger="hover">
              <div v-for="(item, index) in returnContent(scope.row.text.data)" :key="index">
                <p>{{ item.key_name }}：{{ item.value }}</p>
              </div>
              <el-link style="width: 100%; text-align: center" slot="reference" :underline="false" type="primary">
                <span>查看模板内容</span>
              </el-link>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="跳转地址" show-overflow-tooltip>
          <template slot-scope="scope">
            <div v-if="scope.row.text.totype === 1 || scope.row.text.totype === 2">
              <el-tag>{{ scope.row.text.totype === 1 ? "小程序" : "网址" }}</el-tag>
              <span style="margin-left: 10px">
                {{
                scope.row.text.totype === 1
                ? scope.row.text.miniprogram_pagepath
                : scope.row.text.url
                }}
              </span>
            </div>
            <span v-else>不跳转</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button v-if="is_auth('usermsgtpl.wxtemplate.issave')" @click="editTemplate(scope.row)" type="primary"
              size="mini">编辑
            </el-button>
            <el-button v-if="is_auth('usermsgtpl.wxtemplate.isdel')" @click="deleteTemplate(scope.row)" type="danger"
              size="mini">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <!--分页-->
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
      <!--编辑-->
      <el-dialog v-loading="loading" :title="chooseTemplate.key_id ? '修改模板' : '新增模板'" :visible.sync="isAddShow"
        width="50%" :close-on-press-escape="false" :close-on-click-modal="false" @close="clearState">
        <el-row>
          <el-form size="medium" ref="form" :label-width="this.env.label_width">
            <el-col :span="24">
              <el-form-item label="模板选择">
                <el-select :disabled="chooseTemplate.key_id ? true : false" v-model="name" style="width: 50%"
                  placeholder="请选择模板" @change="changeTemplate">
                  <el-option v-for="item in templateData" :key="item.template_id" :label="item.title"
                    :value="item.template_id" />
                </el-select>
              </el-form-item>
            </el-col>
            <div v-if="chooseTemplate.template_id">
              <!-- 模板名称 -->
              <el-col :span="24">
                <el-form-item label="模板名称">
                  <el-input v-model="chooseTemplate.name" maxlength="20" show-word-limit placeholder="请填写模板名称"
                    rows="3" />
                </el-form-item>
              </el-col>
              <h3>模板内容填写</h3>
              <!-- 模板内容 -->
              <el-col v-for="(item, index) in chooseTemplate.content" :key="index" :span="24">
                <el-form-item :label="item.key_name">
                  <el-input v-model="item.value" :placeholder="`请填写模板${item.key_name}内容`" maxlength="20"
                    show-word-limit />
                </el-form-item>
              </el-col>
              <!-- 跳转类型 -->
              <el-col :span="24">
                <el-form-item label="跳转类型">
                  <el-select v-model="chooseTemplate.totype" style="width: 50%" placeholder="请选择跳转类型">
                    <el-option label="小程序" :value="1" />
                    <!-- <el-option label="网页" :value="2" /> -->
                    <el-option label="不跳转" :value="0" />
                  </el-select>
                </el-form-item>
              </el-col>
              <!-- 跳转小程序地址 -->
              <el-col v-if="chooseTemplate.totype === 1" :span="24">
                <el-form-item label="跳转小程序地址">
                  <el-input v-model="chooseTemplate.miniprogram_pagepath" show-word-limit rows="3" />
                </el-form-item>
              </el-col>
              <!-- 跳转网页地址 -->
              <el-col v-if="chooseTemplate.totype === 2" :span="24">
                <el-form-item label="跳转url地址">
                  <el-input v-model="chooseTemplate.url" show-word-limit rows="3" />
                </el-form-item>
              </el-col>
            </div>
          </el-form>
        </el-row>
        <el-row style="margin: 10px 0px">
          <el-button v-if="is_auth('usermsgtpl.wxtemplate.issave')" size="medium" type="primary" @click="save">确 定
          </el-button>
          <el-button size="medium" @click="isAddShow = false">取 消</el-button>
        </el-row>
      </el-dialog>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      page_name: "小程序消息模板",
      loading: true, // 加载状态
      issearch: false, // 搜索是否展示
      search: {}, // 搜索内容
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 列表内容
      templateData: [], // 可用模板内容
      isAddShow: false,
      info: {},
      chooseTemplate: {
        miniprogram_pagepath: "",
        url: "",
      },
      name: "",
      text: {
        template_id: "",
        totype: 0,
        miniprogram_pagepath: "",
        url: "",
      },
    };
  },
  // 创建
  created() {
    this.init();
    // this.gettag();
  },
  methods: {
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 初始化
    init() {
      this.search = {};
      this.searchtime = null;
      this.is_search();
      this.getTemplate();
    },
    //搜索
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 获取列表
    getlist() {
      let postdata = {
        api_name: "usermsgtpl.wxtemplate.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("oss", postdata, (res) => {
        this.loading = false;
        if (res.code === 0) {
          this.tableData = res.data.list;
          this.count = res.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(res);
        }
      });
    },
    // 获取可用模板
    getTemplate() {
      let postdata = {
        api_name: "usermsgtpl.wxtemplate.gettemplate",
        token: this.Tool.get_l_cache("token"),
      };
      this.Tool.post_data("oss", postdata, (res) => {
        if (res.code === 0) {
          this.templateData = res.data.list;
        }
      });
    },
    returnContent(data) {
      let arr = [];
      for (let i in data) {
        arr = arr.concat({
          value: data[i].value,
          key_name: data[i].key_name,
        });
      }
      return arr;
    },
    // 保存与编辑
    save() {
      const { chooseTemplate } = this;
      const {
        content,
        name,
        totype,
        miniprogram_pagepath,
        url,
        template_id,
        title,
        key_id,
      } = chooseTemplate;
      let data = {};
      const postdata = {
        api_name: "usermsgtpl.wxtemplate.issave",
        token: this.Tool.get_l_cache("token"),
        name,
      };
      for (let i in content) {
        data[content[i].key] = {
          value: content[i].value,
          key_name: content[i].key_name,
        };
      }
      postdata["text"] = {
        data,
        template_id,
        title,
        totype,
        miniprogram_pagepath,
        url,
      };
      if (key_id) {
        postdata["key_id"] = key_id;
      }
      this.Tool.post_data("oss", postdata, (json) => {
        if (json.code === 0) {
          this.$message({
            message: key_id ? "修改成功" : "添加成功",
            type: "success",
            duration: this.env.message_duration,
            onClose: () => {
              this.is_search();
              this.isAddShow = false;
              this.chooseTemplate = {};
            },
          });
        } else {
          this.Tool.errormes(json);
        }
      });
    },
    // 选择可用模板
    changeTemplate(e) {
      const { templateData } = this;
      let chooseTemplate = null;
      for (let i in templateData) {
        if (templateData[i].template_id === e) {
          chooseTemplate = templateData[i];
        }
      }
      if (chooseTemplate) {
        this.chooseTemplate = chooseTemplate;
      } else {
        this.$message.warning("选择模板类型错误");
      }
    },
    //打开新增弹窗
    addTemplate() {
      const { templateData } = this;
      if (templateData.length > 0) {
        this.isAddShow = true;
      } else {
        this.$message.warning("暂无可用模板");
      }
    },
    // 编辑模板
    editTemplate(item) {
      const { text, key_id, name } = item;
      const chooseTemplate = {
        title: text.title,
        template_id: text.template_id,
        totype: text.totype,
        key_id,
        name,
        url: text.url,
        miniprogram_pagepath: text.miniprogram_pagepath,
      };
      let arr = [];
      for (let i in text.data) {
        arr = arr.concat({
          key: i,
          value: text.data[i].value,
          key_name: text.data[i].key_name,
        });
      }
      chooseTemplate["content"] = arr;
      this.name = text.title;
      this.chooseTemplate = chooseTemplate;
      this.isAddShow = true;
    },
    // 删除模板
    deleteTemplate(item) {
      this.$confirm(`是否删除 ${item.name} 这条模板`, "确认删除", {})
        .then(() => {
          const postdata = {
            api_name: "usermsgtpl.wxtemplate.isdel",
            token: this.Tool.get_l_cache("token"),
            key_id: item.key_id,
            name: item.name,
          };
          this.Tool.post_data("oss", postdata, (json) => {
            if (json.code === 0) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: this.env.message_duration,
                onClose: () => {
                  this.is_search();
                },
              });
            } else {
              this.Tool.errormes(json);
            }
          });
        })
        .catch(() => { });
    },
    // 初始化数据
    clearState() {
      this.isAddShow = false;
      this.name = "";
      this.chooseTemplate = {
        miniprogram_pagepath: "",
        url: "",
      };
    },
  },
};
</script>
